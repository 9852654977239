import React, { useEffect, useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  Divider,
  Typography,
  Skeleton,
  ListItem,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from 'types/types';
import Export from './NotificationItems/Export';
import NIService, { INIService } from 'services/NIService';
import { useDispatch, useSelector } from 'react-redux';
import { updateExportStatuses } from 'features/notificationCenter';
import { useTheme } from 'context/ThemeContext';

type NotificationCenterProps = {
  open: boolean;
  onClose: () => void;
};

const NotificationCenter: React.FC<NotificationCenterProps> = ({
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const notificationCenter = useSelector(
    (state: RootState) => state.notificationCenter
  );

  useEffect(() => {
    let interval;
    const setStatusInterval = () => {
      // Set interval to fetch data every 2 seconds
      interval = setInterval(fetchCheckExportStatuses, 2000);
    };

    window.addEventListener('update-notifications-status', setStatusInterval);

    const fetchCheckExportStatuses = async () => {
      try {
        // Call NI API to get updated export status.
        const niService: INIService = new NIService();
        const response = await niService.checkExportStatuses();
        const updatedNotifications = response.map((notification) => ({
          ...notification,
          /* Set to read if any download already triggered on file 
             or in case error occurred(styled accordingly in such case). */
          isRead: notification.DownloadCount > 0 || notification.Status === 2,
        }));
        dispatch(updateExportStatuses(updatedNotifications));
        if (!response.some((_) => _.Status === 0)) {
          // If all exports are done, stop the interval to avoid further calls to API.
          clearInterval(interval);
        }
      } catch (error) {
        console.error('Error fetching notification data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    // Fetch data immediately on component mount.
    setStatusInterval();

    return () => {
      // Cleanup the interval and remove any listeners on component unmount.
      window.removeEventListener('export-dashboard-clicked', setStatusInterval);
      clearInterval(interval);
    };
  }, [dispatch]);

  const skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: 350, top: 60 } }}
      classes={{
        paper: `notification-drawer ${theme}`,
      }}
    >
      <Slide
        in={open}
        timeout={350}
        direction="left"
        easing="cubic-bezier(0.25, 0.1, 0.25, 1)"
        mountOnEnter
        unmountOnExit
      >
        <div>
          <div className={`notification ${theme}`}>
            <Typography className={`notification-title ${theme}`}>
              Notifications
            </Typography>
            <IconButton
              className={`notification-icon ${theme}`}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider className={`divider ${theme}`} />
          <List className={`notification-list ${theme}`}>
            {isLoading ? ( // Check if there are no notification items
              skeletonItems.map((index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Typography
                      component="div"
                      variant="caption"
                      sx={{ width: '100%' }}
                    >
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="40%"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="70%"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      />
                    </Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : notificationCenter.export.length === 0 ? (
              <ListItem>
                <Typography variant="body2" className={`caption-text ${theme}`}>
                  No notifications found.
                </Typography>
              </ListItem>
            ) : (
              notificationCenter.export.map((exportItem) => (
                <React.Fragment key={exportItem.Token}>
                  <Export item={exportItem} />
                  <Divider className={`notification-divider ${theme}`} />
                </React.Fragment>
              ))
            )}
          </List>
        </div>
      </Slide>
    </Drawer>
  );
};

export default NotificationCenter;
