import React, { useContext, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { NiKendoContext } from '../context/NiKendoContext';
import Loader from 'components/Common/Loader';
import { RootState } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { hideMenu } from '../features/applicationState';

export default function NiKendo() {
  const { niKendoModule } = useContext(NiKendoContext);
  const appLayoutRef = useRef<any>(null);
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { selectedItem } = useSelector(
    (state: RootState) => state.applicationState
  );
  const currentView = niKendoModule.application.layout.currentView();

  useEffect(() => {
    const renderLayout = async () => {
      if (niKendoModule?.application?.layout && appLayoutRef.current) {
        niKendoModule.application.layout
          .kendoLayout()
          .render(appLayoutRef.current);
        dispatch(hideMenu());
      }
    };
    renderLayout();
  }, [
    niKendoModule?.application?.layout,
    appLayoutRef,
    id,
    selectedItem,
    currentView,
    location,
    dispatch,
  ]);

  return (
    <>
      {niKendoModule && !niKendoModule.application && <Loader />}

      <div id="app" style={{ width: '100%' }}>
        <div id="appLayout" ref={appLayoutRef}></div>
      </div>
    </>
  );
}
