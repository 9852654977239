import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loader() {
  return (
    <div className="mui-loader">
      <CircularProgress size={40} className="circular-progress" />
    </div>
  );
}
