import './assets/styles/main.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { IIdentityProviderConfig } from './models/IIdentityProviderConfig';
import { ThemeProvider } from 'context/ThemeContext';
import { Provider } from 'react-redux';
import store from 'store';
import AuthService from 'services/AuthService';

const container = document.getElementById('root');
const root = createRoot(container!);
const render = (authProviderConfig?: IIdentityProviderConfig) => {
  if (authProviderConfig) {
    root.render(
      <AuthProvider {...authProviderConfig}>
        <Provider store={store}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Provider>
      </AuthProvider>
    );
  } else {
    root.render(<h3>Error Found</h3>);
  }
};

async function initializeApp() {
  await AuthService.initConfig(); // Wait for the configuration to be fetched
  const config = AuthService.getConfig();
  if (config) {
    render(config);
  } else {
    render();
  }
}

initializeApp().catch((error) => {
  console.error('Error initializing app:', error);
  render();
});

reportWebVitals();
