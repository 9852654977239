import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import applicationStateReducer from '../features/applicationState';
import notificationsReducer from '../features/notifications';
import notificationCenterReducer from '../features/notificationCenter';

import { RootState } from '../types/types';

const store = configureStore({
  reducer: {
    applicationState: applicationStateReducer,
    notifications: notificationsReducer,
    notificationCenter: notificationCenterReducer,
    // Add more reducers for other features here
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability checks
    }),
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
