import React, { useState } from 'react';
import { ListItem, ListItemText, Typography, Grid, Box } from '@mui/material';
import { FileInfo } from 'types/types'; // Adjust the import path based on your project structure
import CustomBorderLinearProgress from 'components/Common/CustomBorderLinearProgress';
import { apiUrls } from 'constants/apiUrls';
import Popup from 'components/Common/Popup';
import PopupActions from 'components/Common/PopupActions';
import { useTheme } from 'context/ThemeContext';

interface ExportProps {
  item: FileInfo;
}

const statusMapping = {
  '0': 'In Progress',
  '1': 'Completed',
  '2': 'Failed',
};

const Export: React.FC<ExportProps> = ({ item }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<any>();
  const [popupContent, setPopupContent] = useState<any>();
  const [popupActions, setPopupActions] = useState<any>();
  const { theme } = useTheme();
  const { CreateDate, Status, Token, FriendlyName, isRead, Details } = item;
  const formattedCreateDate = new Date(CreateDate).toLocaleString(); // Format the CreateDate
  const statusText = statusMapping[Status] || 'Unknown'; // Use status mapping or show "Unknown"

  const handleDownload = () => {
    console.log(`Downloading file with token: ${Token}`);
    const url = `${apiUrls.fileDownloadUrl}?token=${Token}`;
    window.open(url);
    // Notification status needs to be updated since should be marked as read(downloaded).
    window.dispatchEvent(new Event('update-notifications-status'));
  };

  const handleErrorDetails = () => {
    console.log(`Error Details for file with token: ${Token}`);
    setPopupTitle('Error Details');
    setPopupContent(<Typography>{Details}</Typography>);
    setPopupActions(
      <PopupActions
        handleCloseButtonClick={() => {
          setOpenPopup(false);
        }}
      />
    );
    setOpenPopup(true);
  };

  const getStatusClassName = () => {
    return `${
      statusText === 'In Progress'
        ? 'in-progress'
        : statusText === 'Failed'
        ? 'failed'
        : 'completed'
    }`;
  };

  const getActionClassName = () => {
    return `${
      statusText === 'In Progress'
        ? 'in-progress'
        : statusText === 'Failed'
        ? 'error'
        : 'download'
    }`;
  };

  return (
    <React.Fragment>
      <Popup
        open={openPopup}
        title={popupTitle}
        content={popupContent}
        onClose={() => {
          setOpenPopup(false);
        }}
        actions={popupActions}
      />
      <ListItem
        className={`export-item ${theme} ${
          isRead ? (statusText === 'Failed' ? 'read-error' : 'read') : 'unread'
        }`}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={12}>
            <Box className="export-name">
              <ListItemText
                primary={FriendlyName}
                secondary={
                  <Typography
                    variant="body2"
                    className={`create-date ${theme}`}
                  >
                    Created: {formattedCreateDate}
                  </Typography>
                }
              />
            </Box>
          </Grid>
          {statusText !== 'Failed' && (
            <CustomBorderLinearProgress
              value={100}
              variant={
                statusText === 'In Progress' ? 'indeterminate' : 'determinate'
              }
            />
          )}
          <Grid item xs={12} md={12}>
            <Box className="export-status">
              <Typography
                variant="body2"
                color="textSecondary"
                onClick={() => {
                  if (statusText === 'Completed') {
                    handleDownload();
                  } else if (statusText === 'Failed') {
                    handleErrorDetails();
                  }
                }}
                className={getActionClassName()}
              >
                {statusText === 'Failed'
                  ? 'Error Details'
                  : statusText === 'Completed' || statusText === 'In Progress'
                  ? 'Download'
                  : ''}
              </Typography>
              <Typography className={getStatusClassName()} variant="body2">
                {statusText}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </React.Fragment>
  );
};

export default Export;
