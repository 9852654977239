import React, { FC, useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
}

const Popup: FC<PopupProps> = ({ open, onClose, title, content, actions }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Dialog open={open} onClose={onClose} className={`popup ${theme}`}>
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent className="dialog-content">{content}</DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Popup;
