import { SidebarItem } from 'types/types';

export const findItemByValueRecursively = (
  value: string,
  items: SidebarItem[]
): SidebarItem | null => {
  for (const item of items) {
    if (item.value === value) {
      return item; // Found the item with the matching value
    }

    if (item.items && item.items.length > 0) {
      // Recursively search in the nested items
      const nestedItem = findItemByValueRecursively(value, item.items);
      if (nestedItem) {
        return nestedItem; // Found the item in the nested items
      }
    }
  }

  return null; // Item with the specified value not found
};

export const findTopParentItem = (
  selectedItem: SidebarItem | null,
  sidebarItems: SidebarItem[]
): SidebarItem | null => {
  if (!selectedItem) {
    return null; // If selectedItem is null, there is no top-level parent
  }

  const findParentItemRecursively = (
    item: SidebarItem,
    targetValue: string
  ): SidebarItem | null => {
    if (item.value === targetValue) {
      // If the item is the target item, return it as the location
      return item;
    }

    // If the item has nested items, recursively search for the target item in them
    if (item.items && item.items.length > 0) {
      for (const nestedItem of item.items) {
        const foundItem = findParentItemRecursively(nestedItem, targetValue);
        if (foundItem) {
          // If the target item is found in nested items, return it
          return foundItem;
        }
      }
    }

    // Target item not found in the current item or its nested items
    return null;
  };

  // Find the location of the selected item in the sidebarItems
  if (selectedItem.value) {
    for (const item of sidebarItems) {
      const foundItem = findParentItemRecursively(item, selectedItem.value);
      if (foundItem) {
        // If the target item is found, find its top-level parent
        return item;
      }
    }
  }

  return null; // Selected item not found in sidebarItems
};
