import { createSlice } from '@reduxjs/toolkit';
import { NotificationCenter, FileInfo } from 'types/types';

const initialState: NotificationCenter = {
  export: [],
  newExportSuccess: false,
  newExportFail: false,
};

const notificationCenterSlice = createSlice({
  name: 'notificationCenter',
  initialState,
  reducers: {
    updateExportStatuses: (state, action) => {
      state.newExportSuccess = checkNewExportsReady(
        state.export,
        action.payload,
        true
      );
      state.newExportFail = checkNewExportsReady(
        state.export,
        action.payload,
        false
      );
      state.export = action.payload;
    },
  },
});

// Utility used to check if new exports completed(ready or failing).
const checkNewExportsReady = (
  prevExport: FileInfo[],
  newExport: FileInfo[],
  successCheck: boolean
): boolean => {
  let newExportReady = false;
  const ongoingExports = prevExport.filter((_: FileInfo) => _.Status === 0); // 0: in progress.
  if (ongoingExports.length > 0) {
    ongoingExports.forEach((exportFile: FileInfo) => {
      const expectedStatus = successCheck ? 1 : 2; // 1: success, 2: error.
      const matchExportFile = newExport.find(
        (_: FileInfo) => _.Token === exportFile.Token
      );
      if (matchExportFile && matchExportFile.Status === expectedStatus) {
        newExportReady = true;
        return true;
      }
    });
  }
  return newExportReady;
};

export const { updateExportStatuses } = notificationCenterSlice.actions;
export default notificationCenterSlice.reducer;
