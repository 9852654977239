import React, { useState } from 'react';
import { IconButton, Tooltip, Badge } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuth } from 'react-oidc-context';
import Popup from 'components/Common/Popup';
import About from './About';
import { showAlert, toggleNotification } from 'features/applicationState';
import { useSelector, useDispatch } from 'react-redux';
import { AlertState, RootState } from 'types/types';
import PopupActions from 'components/Common/PopupActions';
import {
  NightsStay as DarkModeIcon,
  WbSunny as LightModeIcon,
} from '@mui/icons-material';
import { useTheme } from 'context/ThemeContext';
import NECount from './NECount';
import AboutActions from './AboutActions';
import NIService, { INIService } from 'services/NIService';

export default function HeaderNavigation() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<any>();
  const [popupContent, setPopupContent] = useState<any>();
  const [popupActions, setPopupActions] = useState<any>();
  const { theme, toggleTheme } = useTheme();
  const darkModeIcon = theme === 'light' ? <LightModeIcon /> : <DarkModeIcon />;
  const notifications = useSelector(
    (state: RootState) => state.notificationCenter.export
  );
  const unreadCount = notifications.filter(
    (notification) => !notification.isRead
  ).length;
  const handleOnClickLogout = () => {
    auth.signoutRedirect();
  };
  const notificationData = useSelector(
    (state: RootState) => state.notifications.notificationData
  );

  const onClickAboutIcon = () => {
    setPopupTitle('About intelStream');
    setPopupContent(<About></About>);
    setPopupActions(
      <AboutActions
        handleCloseButtonClick={() => {
          setOpenPopup(false);
        }}
      />
    );
    setOpenPopup(true);
  };

  const downloadFile = async (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: 'application/octetstream' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onClickUserGuide = async () => {
    try {
      const niService: INIService = new NIService();
      const response = await niService.DownloadUserGuide();
      await downloadFile(response, 'NI-PH-User-Guide.pdf');
    } catch (error) {
      console.log(error);
      const alertData: AlertState = {
        severity: 'warning',
        title: 'User Guide Not Available',
        message: 'The User Guide Document is not available.',
        open: true,
      };
      dispatch(showAlert(alertData));
    }
  };

  const onClickNECount = () => {
    setPopupTitle('NE Count Indicator');
    setPopupContent(<NECount />);
    setPopupActions(
      <PopupActions
        handleCloseButtonClick={() => {
          setOpenPopup(false);
        }}
      />
    );
    setOpenPopup(true);
  };

  const handleDarkModeClick = () => {
    toggleTheme();
  };

  const handleNotificationToggle = () => {
    dispatch(toggleNotification());
  };

  return (
    <div className="header-navigation ">
      <Popup
        open={openPopup}
        title={popupTitle}
        content={popupContent}
        onClose={() => {
          setOpenPopup(false);
        }}
        actions={popupActions}
      />
      {notificationData && notificationData.IsNeCountEnabled && (
        <Tooltip title="NE Count" arrow>
          <IconButton
            className="info-icon"
            disableRipple
            onClick={onClickNECount}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="User Guide" arrow>
        <IconButton
          color="inherit"
          className="help-outline"
          disableRipple
          onClick={onClickUserGuide}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="About" arrow onClick={onClickAboutIcon}>
        <IconButton color="inherit" className="about-icon" disableRipple>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Notifications" arrow onClick={handleNotificationToggle}>
        <IconButton
          color="inherit"
          className="about-icon"
          disableRipple
          id="notificationsButton"
        >
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="Theme" arrow>
        <IconButton
          color="inherit"
          aria-label="Toggle dark mode"
          className="about-icon"
          onClick={handleDarkModeClick}
          disableRipple
        >
          {darkModeIcon}
        </IconButton>
      </Tooltip>
      <Tooltip title="Logout" arrow>
        <IconButton
          color="inherit"
          className="logout-icon"
          disableRipple
          onClick={handleOnClickLogout}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
