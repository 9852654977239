import React from 'react';
import { LinearProgress } from '@mui/material';

interface BorderLinearProgressProps {
  value: number;
  variant: 'determinate' | 'indeterminate' | 'buffer' | 'query';
}

const CustomBorderLinearProgress: React.FC<BorderLinearProgressProps> = ({
  value,
  variant,
}) => {
  return (
    <LinearProgress
      className="custom-border-linear-progress"
      variant={variant}
      value={value}
    />
  );
};

export default CustomBorderLinearProgress;
