import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { ThemeContext } from 'context/ThemeContext';

interface PopupActionsProps {
  handleCloseButtonClick: React.MouseEventHandler;
}

const PopupActions: React.FC<PopupActionsProps> = ({
  handleCloseButtonClick,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`popup-actions ${theme}`}>
      <Button
        variant="contained"
        onClick={handleCloseButtonClick}
        className="close-button"
      >
        Close
      </Button>
    </div>
  );
};

export default PopupActions;
