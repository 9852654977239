import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
  Divider,
  Tooltip,
  Icon,
  Fade,
} from '@mui/material';
import { ArrowRight, ExpandMore } from '@mui/icons-material';
import { ThemeContext } from 'context/ThemeContext';
import Search from './Search';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, SidebarItem } from 'types/types';
import { toggleMenu, updateSelectedItem } from 'features/applicationState';
import { NiKendoContext } from 'context/NiKendoContext';
import OpenedViews from './OpenedViews';

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const { theme } = useContext(ThemeContext);
  const openedViewsRef = useRef<HTMLDivElement>(null);
  const maxHeightValueRef = useRef(120);
  const { niKendoModule } = useContext(NiKendoContext);

  const { sidebarItems, menuClosed, selectedItem, openedViews } = useSelector(
    (state: RootState) => state.applicationState
  );

  const handleSearchChange = useCallback((value: string) => {
    setExpandStates({});
    setSearchText(value);
  }, []);

  const handleIconClick = (itemKey: string, isExpanded: boolean) => {
    handleExpandToggle(itemKey, isExpanded);
    dispatch(toggleMenu());
  };

  const findItemByValue = (
    items: SidebarItem[],
    value: string
  ): SidebarItem | null => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.value === value) {
        return item;
      }
      if (item.items) {
        const foundInSubitems = findItemByValue(item.items, value);
        if (foundInSubitems) {
          return foundInSubitems;
        }
      }
    }
    return null;
  };

  const handleViewClick = (itemValue: string) => {
    const selectedItem: any = findItemByValue(sidebarItems, itemValue);
    dispatch(updateSelectedItem(selectedItem));

    niKendoModule.application.layout.openView({
      type: selectedItem.type,
      value: itemValue,
      properties: selectedItem.properties,
    });
  };

  const filterSidebarItemsRecursively = useCallback(
    (items: SidebarItem[], searchText: string) => {
      return items?.filter((item) => {
        const isMatchingText = item.text
          .toLowerCase()
          .includes(searchText.toLowerCase());
        if (isMatchingText) return true;

        if (item.items && item.items.length > 0) {
          // Recursively filter sub-items
          const filteredItems = filterSidebarItemsRecursively(
            item.items,
            searchText
          );
          if (filteredItems.length > 0) {
            item.isExpanded = true;
            item.items = filteredItems;
            return true;
          }
        }
        return isMatchingText;
      });
    },
    []
  );

  const filteredSidebarItems = useMemo(
    () =>
      filterSidebarItemsRecursively(structuredClone(sidebarItems), searchText),
    [filterSidebarItemsRecursively, sidebarItems, searchText]
  );

  const [expandStates, setExpandStates] = useState<{
    [key: string]: boolean;
  }>({});

  const handleExpandToggle = (key: string, isExpanded: boolean) => {
    setExpandStates((prevStates) => ({
      ...prevStates,
      [key]: prevStates[key] == undefined ? !isExpanded : !prevStates[key],
    }));
  };

  const renderSidebarItems = (
    items: SidebarItem[],
    level: number,
    parentKey: string
  ) => {
    return items.map((item, index) => {
      const nestedLevel = level + 1;
      const itemKey = `${parentKey}-${index}`;
      const isInitiallyOpen = expandStates[itemKey] || item.isExpanded;

      const paddingLeft = level === 0 ? 0 : 5 * nestedLevel; // Apply padding only for sub-level items
      const IconComponent = item.iconClass ? (
        <Icon
          key={index}
          className={`is-icon-main is-icon ${item.iconClass}`}
          style={{ marginLeft: `${menuClosed ? '10px' : '0px'}` }}
        />
      ) : (
        <></>
      );

      return (
        <React.Fragment key={itemKey}>
          <ListItem
            button
            sx={{
              paddingLeft: `${paddingLeft}px`,
              backgroundColor:
                item.value === selectedItem?.value
                  ? theme == 'light'
                    ? '#ea565d42'
                    : '#254769'
                  : 'auto',
            }}
            onClick={() => {
              item.type === 'VIEW' || item.type === 'DYNAMICVIEW'
                ? handleViewClick(item.value)
                : menuClosed
                ? handleIconClick(itemKey, item.isExpanded)
                : handleExpandToggle(itemKey, item.isExpanded);

              item.isExpanded = false;
            }}
          >
            {menuClosed ? (
              <Tooltip title={item.text} placement="right">
                {IconComponent}
              </Tooltip>
            ) : (
              <>
                {IconComponent}
                <Fade
                  in={!menuClosed}
                  timeout={1200}
                  easing="cubic-bezier(0.25, 0.1, 0.25, 1)"
                  mountOnEnter
                  unmountOnExit
                >
                  <ListItemText
                    primary={item.text}
                    className={`list-item-text ${
                      item.iconClass ? '' : 'last-list-item-text'
                    }`}
                  />
                </Fade>
              </>
            )}
            {!menuClosed && item.items && item.items.length > 0 && (
              <ListItemIcon>
                {isInitiallyOpen ? <ExpandMore /> : <ArrowRight />}
              </ListItemIcon>
            )}
          </ListItem>
          {!menuClosed && item.items && item.items.length > 0 && (
            <Collapse in={isInitiallyOpen}>
              <List>
                {renderSidebarItems(item.items, nestedLevel, itemKey)}
              </List>
            </Collapse>
          )}
          {index < items.length - 1 && <Divider />}
        </React.Fragment>
      );
    });
  };

  const sidebarContent = renderSidebarItems(
    filteredSidebarItems,
    0,
    'top-level'
  );

  const handleOpenedViewsHeightChange = (divHeight: number) => {
    const totalHeight = divHeight + 120;
    maxHeightValueRef.current = totalHeight;
  };

  const handleCloseView = (viewId: string) => {
    niKendoModule.application.layout.handleCloseOpenedViewByItemId(viewId);
    const updatedOpenedViews = openedViews.filter(
      (view: any) => view.viewId !== viewId
    );
    if (updatedOpenedViews.length === 0) {
      niKendoModule.application.layout.openView({
        type: 'VIEW',
        value: 'landingPageView',
      });
    }
  };

  return (
    <Drawer
      sx={{
        width: !menuClosed ? '250px' : '0px',
      }}
      variant="permanent"
      className={`custom-drawer ${theme}`}
      open={menuClosed}
      style={{ position: 'relative' }}
    >
      <Grid container className={`sidebar ${theme}`}>
        {!menuClosed && (
          <>
            <Grid container className="search-container">
              <Search
                handleSearchChange={handleSearchChange}
                searchText={searchText}
              ></Search>
            </Grid>
            {openedViews.length > 0 && (
              <Grid container>
                <OpenedViews
                  openedViewsRef={openedViewsRef}
                  handleOpenedViewsHeightChange={handleOpenedViewsHeightChange}
                  handleCloseView={handleCloseView}
                />
              </Grid>
            )}
          </>
        )}
        <List
          style={{
            overflowY: menuClosed ? 'hidden' : 'auto',
            maxHeight: `calc(100vh - ${maxHeightValueRef.current}px)`,
          }}
          className="sidebar-content"
        >
          {sidebarContent}
        </List>
      </Grid>
    </Drawer>
  );
};

export default SideBar;
