import React, { useCallback, useMemo, useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { FilledInput, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

interface SearchProps {
  searchText: string;
  handleSearchChange: (searchText: string) => void;
}

const Search: React.FC<SearchProps> = ({ searchText, handleSearchChange }) => {
  const { theme } = useContext(ThemeContext);

  const MAX_INPUT_LENGTH = useMemo(() => 15, []);

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputText = event.target.value;
      // Check if inputText length is within the allowed limit
      if (inputText.length <= MAX_INPUT_LENGTH) {
        handleSearchChange(inputText);
      }
    },
    [MAX_INPUT_LENGTH, handleSearchChange]
  );

  return (
    <div>
      <FilledInput
        sx={{ borderRadius: '20px' }}
        disableUnderline={true}
        className={`custom-text-field ${theme}`}
        value={searchText}
        onChange={handleInputChange}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className="search-icon" />
          </InputAdornment>
        }
        endAdornment={
          searchText && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                className="close-icon"
                onClick={handleClearSearch}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </div>
  );
};

export default Search;
