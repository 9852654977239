import React, {
  createContext,
  useState,
  useContext,
  FunctionComponent,
} from 'react';

// Define theme names as constants
const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';

// Define a type for theme names
type ThemeName = typeof LIGHT_THEME | typeof DARK_THEME;

interface ThemeContextProps {
  theme: ThemeName;
  toggleTheme: () => void;
}

const initialThemeContext: ThemeContextProps = {
  theme: LIGHT_THEME,
  toggleTheme: () => {},
};

export const ThemeContext =
  createContext<ThemeContextProps>(initialThemeContext);

export const ThemeProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeName>(LIGHT_THEME);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME
    );
  };

  const themeContextValue: ThemeContextProps = {
    theme,
    toggleTheme,
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => useContext(ThemeContext);
