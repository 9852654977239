import React, { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { Typography, Divider } from '@mui/material';

export default function About() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`about-app ${theme}`}>
      <div className="about-logo-container">
        <span className="logo-svg"></span>
        <span className="ni-svg" />
      </div>
      <Divider />
      <Typography variant="body1" className="description">
        IntelStream’s Network Insight addresses CSPs’ need for a technical data
        umbrella platform that provides engineering data correlations for all
        network related data inputs such as Performance Management,
        Configuration Management, Faults, Probes, Coverage and Drive Test in one
        centralized solution, thus enhancing the overall Network performance and
        customer experience.
      </Typography>
    </div>
  );
}
