import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

const SessionExpired = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid
        item
        xs={12}
        textAlign="center"
        className="session-expired-container"
      >
        <Typography variant="h2" gutterBottom>
          Session Expired
        </Typography>
        <Typography variant="h5">
          Your session has expired. Please log in again to continue.{' '}
          <Link component={NavLink} to="/" color="primary">
            Go to Login
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SessionExpired;
