import React, { useEffect, useContext } from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from 'types/types';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'context/ThemeContext';
import { NiKendoContext } from '../../../context/NiKendoContext';

type OpenedViewsProps = {
  openedViewsRef: React.RefObject<HTMLDivElement>;
  handleOpenedViewsHeightChange: (divHeight: number) => void;
  handleCloseView: (viewId: string) => void;
};

export default function OpenedViews({
  openedViewsRef,
  handleOpenedViewsHeightChange,
  handleCloseView,
}: OpenedViewsProps) {
  const { theme } = useContext(ThemeContext);

  const { openedViews } = useSelector(
    (state: RootState) => state.applicationState
  );
  const { niKendoModule }: any = useContext(NiKendoContext);

  const handleViewClick = (view: any) => {
    niKendoModule.application.layout.openView({
      type: view.type,
      value: view.viewId,
      properties: view.properties,
    });
  };

  const handleCloseIconClick = (viewId: string) => {
    handleCloseView(viewId);
  };
  useEffect(() => {
    const handleResize = () => {
      if (openedViewsRef.current) {
        const divHeight = openedViewsRef.current.clientHeight;
        handleOpenedViewsHeightChange(divHeight); // Notify the SideBar component
      }
    };

    // Create a ResizeObserver to observe height changes of opened-views div
    const resizeObserver = new ResizeObserver(handleResize);

    if (openedViewsRef.current) {
      resizeObserver.observe(openedViewsRef.current);
    }

    // Clean up the ResizeObserver on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [openedViewsRef, handleOpenedViewsHeightChange]);

  return (
    <div className={`opened-views ${theme}`} ref={openedViewsRef}>
      <List className="list">
        {openedViews.map((view: any, key: any) => (
          <ListItem key={key} button className="item">
            <ListItemText
              primary={view.title}
              className="text"
              onClick={() => handleViewClick(view)}
            />
            <IconButton onClick={() => handleCloseIconClick(view.viewId)}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
