import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  FunctionComponent,
} from 'react';
import AuthService from '../services/AuthService';
import Loader from 'components/Common/Loader';
import NIService, { INIService } from 'services/NIService';
import { updateSidebarItems } from 'features/applicationState';
import { useDispatch } from 'react-redux';

interface NiKendoContextProps {
  niKendoModule: any;
  isLoading: boolean;
}

export const NiKendoContext = createContext<NiKendoContextProps>({
  niKendoModule: null,
  isLoading: true,
});

export const NiKendoProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [niKendoModule, setNiKendoModule] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const initNiKendoApp = (niKendoApp: any, token: any, apps: any[]) => {
    if (niKendoApp && typeof niKendoApp.init === 'function') {
      return niKendoApp
        .init(token, apps)
        .then(() => {
          setNiKendoModule(niKendoApp);
          setIsLoading(false);
        })
        .catch((error: any) => {
          console.error('Error during niKendoApp.init:', error);
          // Optionally handle error state or display an error message to the user
        });
    } else {
      console.error('niKendoApp.init is not a function');
      // Optionally handle error state or display an error message to the user
    }
  };

  useEffect(() => {
    const initNiKendoModule = async () => {
      await AuthService.initConfig();
      const user = AuthService.getUser();
      const niService: INIService = new NIService();
      const response = await niService.GetMenuItems();
      // store menu items in redux
      dispatch(updateSidebarItems(response));
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        import('remoteMainEntry/mainApp')
          .then((module) => {
            const niKendoApp = module.default;
            initNiKendoApp(niKendoApp, user, response);
          })
          .catch((error) => {
            console.error('Failed to import module:', error);
            // Optionally handle error state or display an error message to the user
          });
      } else {
        import('@yuvo/ni-kendo-library') // Import the library
          .then((module) => {
            const niKendoApp = module.default;
            initNiKendoApp(niKendoApp, user, response);
          })
          .catch((error) => {
            console.error('Failed to import module:', error);
            // Optionally handle error state or display an error message to the user
          });
      }
    };

    initNiKendoModule();
  }, [dispatch]);

  // Memoize only niKendoModule
  const memoizedNiKendoModule = useMemo(() => niKendoModule, [niKendoModule]);

  const contextValue: NiKendoContextProps = {
    niKendoModule: memoizedNiKendoModule,
    isLoading,
  };

  // Add a loading indicator or initial state if needed during initialization
  if (isLoading) {
    return <Loader />;
  }

  return (
    <NiKendoContext.Provider value={contextValue}>
      {children}
    </NiKendoContext.Provider>
  );
};
