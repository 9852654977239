import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item xs={12} textAlign="center" className="notfound-container">
        {' '}
        <Typography variant="h2" gutterBottom>
          Oops! Sorry, Page Not Found
        </Typography>
        <Typography variant="h5">
          You should probably go back to{' '}
          <Link component={NavLink} to="/" color="primary">
            Home
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
