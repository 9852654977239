import React, { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { Breadcrumbs, Link, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavigateNext } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Breadcrumb } from 'types/types';
import { NiKendoContext } from '../../../context/NiKendoContext';
import {
  toggleMenu,
  updateBreadcrumb,
  updateSelectedItem,
} from 'features/applicationState';
import { findItemByValueRecursively } from '../CommonService';

const BreadcrumbComponent = () => {
  const { theme } = useContext(ThemeContext);
  const { niKendoModule }: any = useContext(NiKendoContext);

  const { breadcrumbs, sidebarItems } = useSelector(
    (state: RootState) => state.applicationState
  );
  const dispatch = useDispatch();

  const sliceBreadcrumb = (
    breadcrumbs: Breadcrumb[],
    index: number
  ): Breadcrumb[] => {
    // (using slice)
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    return newBreadcrumbs;
  };

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
  };

  const handleBreadcrumbClick = (link: string, index: number) => {
    const selectedItem = findItemByValueRecursively(link, sidebarItems);
    if (selectedItem?.type === 'FOLDER') {
      dispatch(toggleMenu());
    }
    dispatch(updateSelectedItem(selectedItem));
    const updatedBreadcrumbs = sliceBreadcrumb(breadcrumbs, index);
    dispatch(updateBreadcrumb(updatedBreadcrumbs));

    if (index === 1) {
      const props = [{ Name: 'selectedApp', Value: selectedItem?.value }];
      niKendoModule.application.layout.openView({
        type: 'VIEW',
        value: 'landingDashboardsView',
        properties: props,
      });
    } else {
      niKendoModule.application.layout.openView({
        type: 'VIEW',
        value: 'landingPageView',
      });
    }
  };
  const isApplications = breadcrumbs.find((b) => b.label === 'Applications');
  return (
    <div className="breadcrumb-container">
      <IconButton color="inherit" onClick={handleToggleMenu}>
        <MenuIcon className="menu-icon" />
      </IconButton>

      <Breadcrumbs
        separator={<NavigateNext className={'breadcrumb-navigation-next'} />}
        aria-label="breadcrumb"
        classes={{
          separator: `separator ${theme}`,
          root: `breadcrumb ${theme}`,
        }}
      >
        {breadcrumbs.map((breadcrumb, index) => {
          const isLastBreadcrumb = index === breadcrumbs.length - 1;
          if (breadcrumb.link && !isLastBreadcrumb && !isApplications) {
            return (
              <Link
                key={index}
                color="inherit"
                onClick={() =>
                  handleBreadcrumbClick(breadcrumb.link ?? '', index)
                }
                className="text link"
              >
                {breadcrumb.label}
              </Link>
            );
          } else {
            return (
              <Typography key={index} className={`text `} color="inherit">
                {breadcrumb.label}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbComponent;
