import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/types';
import LinearProgress from '@mui/material/LinearProgress';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function NECount() {
  // Access the notificationData from the Redux store
  const notificationData = useSelector(
    (state: RootState) => state.notifications.notificationData
  );
  const { CurrentNeCount, TotalNeCount } = notificationData;
  const percentage = (CurrentNeCount / TotalNeCount) * 100;

  return (
    <div className="ne-count">
      <Tooltip
        title={`${CurrentNeCount} out of ${TotalNeCount}`}
        placement="top"
      >
        <IconButton disableRipple className="info-icon">
          <InfoIcon />
        </IconButton>
      </Tooltip>

      <LinearProgress
        variant="determinate"
        value={percentage}
        className="custom-linear-progress"
      />
    </div>
  );
}
