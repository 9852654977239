import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AlertState, RootState } from 'types/types';
import { ThemeContext } from 'context/ThemeContext';
import NIService, { INIService } from 'services/NIService';
import { showAlert } from 'features/applicationState';

interface AboutActionsProps {
  handleCloseButtonClick: React.MouseEventHandler;
}

const AboutActions: React.FC<AboutActionsProps> = ({
  handleCloseButtonClick,
}) => {
  const productVersion = useSelector(
    (state: RootState) =>
      state.applicationState.applicationSettings.PRODUCT_VERSION
  );

  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const onClickProductVersion = async () => {
    try {
      const niService: INIService = new NIService();
      const response = await niService.DownloadReleaseNotes();
      const pv = productVersion.split('.');
      const fileName = 'Sprint-' + pv[2] + '-ReleaseNotes.pdf';

      await downloadFile(response, fileName);
    } catch (error) {
      console.log(error);
      const alertData: AlertState = {
        severity: 'warning',
        title: 'Release Notes Not Available',
        message: 'The Release Notes Document is not available.',
        open: true,
      };
      dispatch(showAlert(alertData));
    }
  };

  const downloadFile = async (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: 'application/octetstream' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className={`about-actions ${theme}`}>
      <Typography className="product-version" onClick={onClickProductVersion}>
        Product Version : {productVersion}
      </Typography>
      <Button
        variant="contained"
        onClick={handleCloseButtonClick}
        className="close-button"
      >
        Close
      </Button>
    </div>
  );
};

export default AboutActions;
