import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/types';

export default function Logo() {
  const { menuClosed } = useSelector(
    (state: RootState) => state.applicationState
  );

  return (
    <div className={`header-logo ${menuClosed ? 'header-logo-collapsed' : ''}`}>
      <span
        className={`logo-svg ${menuClosed ? 'logo-svg-collapsed' : ''}`}
      ></span>
    </div>
  );
}
