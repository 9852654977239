import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const AppSnackbar: React.FC<{
  show: boolean;
  message: string;
  severity: string;
}> = ({ show, message, severity }) => {
  const [open, setOpen] = useState(show);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Alert
        variant="filled"
        className="snackbar-success"
        severity={severity === 'success' ? 'success' : 'error'}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
