import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import NiKendo from './pages/NiKendo';
import Workspace from './components/Layout/Workspace/Workspace';
import NotFound from './pages/NotFound';
import { useAuth } from 'react-oidc-context';
import { NiKendoProvider } from './context/NiKendoContext';
import WebFont from 'webfontloader';
import Loader from 'components/Common/Loader';
import Unauthorized from 'pages/SessionExpired';
import SessionTimeout from 'components/Common/SessionTimeout';
import { useSelector } from 'react-redux';
import { RootState } from 'types/types';

function App() {
  const auth = useAuth();

  const sessionTimeout = useSelector(
    (state: RootState) =>
      state.applicationState.applicationSettings.SESSION_TIMEOUT
  );

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Roboto:400,300,300italic,400italic,500,500italic,700,700italic',
        ],
      },
    });
  }, []);

  useEffect(() => {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        // add loader
        return;
      case 'signoutRedirect':
        // add loader
        return;
      default:
        if (auth.isLoading) {
          // add loader
          return;
        } else if (auth.error) {
          // add loader
          return;
        } else {
          // add loader
        }
    }
    if (!auth.isAuthenticated) {
      auth.signinRedirect({
        state: {
          originalUrlHash: window.location.hash || '#/view/landingPageView',
        },
      });
    } else {
      // Redirect to base URL when authenticated and not already on the base URL
      if (window.location.search !== '') {
        const state: any = auth.user?.state;
        window.location.href =
          window.location.origin + '/' + state.originalUrlHash;
      }
    }
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth, auth.signinSilent]);

  const handleSessionExpired = () => {
    auth.signoutRedirect();
  };

  if (auth.isAuthenticated) {
    return (
      <div className="main-container">
        {/* required for kendo ni bundle */}
        <div id="appLayout" style={{ display: 'none' }}></div>
        {/* seesion timeout */}
        <SessionTimeout
          timeSpan={parseInt(sessionTimeout)}
          onSessionExpired={handleSessionExpired}
        />
        <BrowserRouter>
          <NiKendoProvider>
            <Workspace>
              <Routes>
                <Route path="/" element={<NiKendo />} />
                <Route path="/:id" element={<NiKendo />} />
                <Route path="/about" element={<About />} />
                <Route path="/sessionExpired" element={<Unauthorized />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Workspace>
          </NiKendoProvider>
        </BrowserRouter>
      </div>
    );
  } else {
    return <Loader />;
  }
}

export default App;
