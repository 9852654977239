import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AlertState,
  ApplicationState,
  Breadcrumb,
  SidebarItem,
} from 'types/types';

const initialState: ApplicationState = {
  breadcrumbs: [
    {
      label: 'Network Insight',
      link: '/',
    },
    {
      label: 'Applications',
    },
  ],
  menuClosed: true,
  notificationToggle: false,
  applicationSettings: {
    BING_MAP_API_KEY: '',
    AUTHENTICATION_TYPE: '',
    HELP_LINK: '',
    FIRST_DAY_OF_WEEK: '',
    WEEK_CALC_RULE: '',
    MAX_VIEWS_OPEN: '',
    ENABLE_EXPORT_COMPRESSION: '',
    MAX_HIERARCHIES_LIMIT: '',
    CORRELATION_CONGESTION_ENABLED: false,
    DEFAULT_DATE_FORMAT: '',
    CHART_AXIS_CONFIG: [],
    PRODUCT_VERSION: '',
    RELEASE_NOTES_LINK: '',
    SESSION_TIMEOUT: '3600', // 1 hours default session timeout
  },
  alert: {
    severity: 'success',
    title: '',
    message: '',
    open: false,
  },
  sidebarItems: [],
  selectedItem: null,
  openedViews: [],
};

const applicationStateSlice = createSlice({
  name: 'applicationState',
  initialState,
  reducers: {
    updateBreadcrumb: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = action.payload;
    },
    toggleMenu: (state) => {
      state.menuClosed = !state.menuClosed;
    },
    updateApplicationSettings: (state, action) => {
      state.applicationSettings = action.payload;
    },
    toggleNotification: (state) => {
      state.notificationToggle = !state.notificationToggle;
    },
    showAlert: (state, action: PayloadAction<AlertState>) => {
      state.alert = action.payload;
    },
    hideAlert: (state) => {
      state.alert.open = false;
    },

    updateSidebarItems: (state, action) => {
      state.sidebarItems = action.payload;
    },
    updateSelectedItem: (state, action: PayloadAction<SidebarItem | null>) => {
      state.selectedItem = action.payload;
    },
    setOpenedViews: (state, action: PayloadAction<any[]>) => {
      state.openedViews = action.payload;
    },
    hideMenu: (state) => {
      state.menuClosed = true;
    },
  },
});

export const {
  updateBreadcrumb,
  toggleMenu,
  updateApplicationSettings,
  toggleNotification,
  showAlert,
  hideAlert,
  updateSidebarItems,
  updateSelectedItem,
  setOpenedViews,
  hideMenu,
} = applicationStateSlice.actions;
export default applicationStateSlice.reducer;
