import React, { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import Logo from './Logo';
import Breadcrumb from './Breadcrumb';
import HeaderNavigation from './HeaderNavigation';

const Header = () => {
  const { theme } = useContext(ThemeContext);

  const headerStyle = {
    backgroundColor: theme,
  };

  return (
    <div className={`header ${theme}`} style={headerStyle}>
      <div className={`header-left-side`}>
        <Logo></Logo>
        <Breadcrumb></Breadcrumb>
      </div>
      <HeaderNavigation></HeaderNavigation>
    </div>
  );
};

export default React.memo(Header);
