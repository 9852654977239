import React from 'react';

const About = () => {
  return (
    <main className="About-main">
      <div className="About-container">
        <h2>About this site</h2>
      </div>
    </main>
  );
};

export default About;
