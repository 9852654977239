import { Alert, Slide, Snackbar, Typography } from '@mui/material';
import { hideAlert } from 'features/applicationState';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/types';

export default function AlertComponent() {
  const dispatch = useDispatch();
  const alertState = useSelector(
    (state: RootState) => state.applicationState.alert
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideAlert());
  };

  const alertStyle: React.CSSProperties = {
    width: '400px',
    margin: '20px auto',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor:
      alertState.severity === 'error'
        ? '#f44336'
        : alertState.severity === 'warning'
        ? '#ff9800'
        : alertState.severity === 'info'
        ? '#2196f3'
        : '#4caf50',
  };

  const titleStyle: React.CSSProperties = {
    fontWeight: 'bold',
    color: '#fff',
    marginRight: '10px',
  };

  const messageStyle: React.CSSProperties = {
    color: '#fff',
  };

  const iconStyle: React.CSSProperties = {
    color: '#fff',
  };

  return (
    <Snackbar
      autoHideDuration={5000}
      open={alertState.open}
      onClose={handleClose}
      sx={{
        marginTop: '60px',
        '& .MuiAlert-root': alertStyle,
        '& .MuiAlert-message': titleStyle,
        '& .MuiAlert-icon': iconStyle,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={(props) => <Slide {...props} direction="left" />}
    >
      <Alert
        onClose={handleClose}
        severity={alertState.severity}
        sx={{
          '& .MuiAlert-root': alertStyle,
          '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }, // Use flex styles
          '& .MuiAlert-icon': iconStyle,
        }}
      >
        <Typography style={titleStyle}>{alertState.title}</Typography>
        <Typography style={messageStyle}>{alertState.message}</Typography>
      </Alert>
    </Snackbar>
  );
}
