import { apiUrls } from 'constants/apiUrls';
import { ApiBase } from './API';
import { FileInfo } from 'types/types';

export interface INIService {
  GetNotificationData: () => Promise<any>;
  GetApplicationSettings: () => Promise<any>;
  DownloadUserGuide: () => Promise<any>;
  DownloadReleaseNotes: () => Promise<any>;
  GetMenuItems: () => Promise<any>;
  checkExportStatuses: () => Promise<FileInfo[]>;
}

class NIService extends ApiBase implements INIService {
  constructor() {
    super('');
  }

  public GetNotificationData = async (): Promise<any> => {
    return this.instance.get<any>(apiUrls.notificationsUrl);
  };

  public GetApplicationSettings = async (): Promise<any> => {
    return this.instance.get<any>(apiUrls.applicationSettingsUrl);
  };

  public DownloadUserGuide = async (): Promise<any> => {
    return this.instance.get<any>(apiUrls.userGuideUrl);
  };

  public DownloadReleaseNotes = async (): Promise<any> => {
    return this.instance.get<any>(apiUrls.releaseNotesUrl);
  };

  public GetMenuItems = async (): Promise<any> => {
    return this.instance.get<any>(apiUrls.navigationUrl);
  };

  public checkExportStatuses = async (): Promise<FileInfo[]> => {
    return this.instance.post<any>(apiUrls.exportStatusUrl);
  };
}

export default NIService;
