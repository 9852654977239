import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import PropTypes from 'prop-types';
import { NiKendoContext } from '../../../context/NiKendoContext';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, RootState, SidebarItem } from 'types/types';
import Loader from 'components/Common/Loader';
import NotificationCenter from 'components/Layout/NotificationCenter/NotificationCenter';
import {
  setOpenedViews,
  updateApplicationSettings,
  toggleNotification,
  updateBreadcrumb,
  updateSelectedItem,
} from 'features/applicationState';
import NIService, { INIService } from 'services/NIService';
import { updateNotificationData } from 'features/notifications';
import AlertComponent from 'components/Common/Alert';
import SideBar from '../SideBar/SideBar';
import {
  findItemByValueRecursively,
  findTopParentItem,
} from '../CommonService';
import AppSnackbar from '../NotificationCenter/AppSnackbar';

type Props = {
  children: any;
};

export const Workspace = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  const { children } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoadingApi, setIsLoadingApi] = useState(true);
  const { isLoading, niKendoModule }: any = useContext(NiKendoContext);
  const { sidebarItems, notificationToggle } = useSelector(
    (state: RootState) => state.applicationState
  );
  // Listen to new notifications coming.
  const notificationCenter = useSelector(
    (state: RootState) => state.notificationCenter
  );
  const handleNotificationToggle = () => {
    dispatch(toggleNotification());
  };

  const memoizedFindItemByValueRecursively = useCallback(
    (value: string, items: SidebarItem[]) =>
      findItemByValueRecursively(value, items),
    []
  );

  // Calculate the id from the pathname using useMemo
  const id = useMemo(() => location.pathname.substring(1), [location]);

  // Get current view to access kendo route
  const currentView = niKendoModule.application.layout.currentView();

  // Use useRef to store the previous value of id
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prevId = useRef(id);

  // Calculate breadcrumb based on selectedItem and id parameter from the route
  const calculateBreadcrumb = useCallback(
    (sidebarItems: SidebarItem[], id: string) => {
      const breadcrumb: Breadcrumb[] = [];

      // Add the first-level breadcrumb item
      breadcrumb.push({
        label: 'Network Insight',
        link: '/',
      });

      const selectedItem = memoizedFindItemByValueRecursively(id, sidebarItems);
      const topParentItem = findTopParentItem(selectedItem, sidebarItems);
      if (topParentItem && topParentItem.value !== selectedItem?.value) {
        breadcrumb.push({
          label: topParentItem.text,
          link: topParentItem.value,
        });
      }
      if (selectedItem) {
        breadcrumb.push({
          label: selectedItem?.text ?? '',
          link: selectedItem?.value ?? '',
        });
      } else {
        breadcrumb.push({
          label: 'Applications',
          link: '/',
        });
      }

      dispatch(updateSelectedItem(selectedItem));

      return breadcrumb;
    },
    [dispatch, memoizedFindItemByValueRecursively]
  );

  useEffect(() => {
    // Add an event listener to receive updates from Kendo
    const handleOpenedViewsUpdate = (updatedArray) => {
      // Handle the updated array of opened views here
      dispatch(setOpenedViews(updatedArray));
      // You can update your component's state or perform any other actions here
    };

    // Assuming you have access to the addEventListener function from the Kendo part
    niKendoModule.application.layout.addEventListener(
      'openedViewsUpdated',
      handleOpenedViewsUpdate
    );

    // Check if any opened views already loaded.
    const openViews = niKendoModule.application.layout.getOpenViews();
    if (openViews.length > 0) dispatch(setOpenedViews(openViews));

    // Don't forget to remove the event listener when your component unmounts
    return () => {
      // Assuming you have access to the removeEventListener function from the Kendo part
      niKendoModule.application.layout.removeEventListener(
        'openedViewsUpdated',
        handleOpenedViewsUpdate
      );
    };
  }, [dispatch, niKendoModule.application.layout]);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        // Call the API using niService
        const niService: INIService = new NIService();
        const response = await niService.GetNotificationData();

        dispatch(updateNotificationData(response));
      } catch (error) {
        console.error('Error fetching notification data:', error);
      }
    };

    const fetchApplicationSettings = async () => {
      try {
        // Call the API using niService
        const niService: INIService = new NIService();
        const response = await niService.GetApplicationSettings();

        dispatch(updateApplicationSettings(response));
      } catch (error) {
        console.error('Error fetching application settings:', error);
      }
    };
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchNotificationData(),
          fetchApplicationSettings(),
        ]);
        setIsLoadingApi(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    let newBreadcrumb: Breadcrumb[] = [];
    if (currentView) {
      let viewid = currentView.viewId;
      if (viewid === 'landingDashboardsView') {
        viewid = currentView.properties.find(
          (p: { Name: string }) => p.Name == 'selectedApp'
        )?.Value;
      }
      newBreadcrumb = calculateBreadcrumb(sidebarItems, viewid);
    } else {
      if (id && id !== prevId.current && id.length > 0) {
        newBreadcrumb = calculateBreadcrumb(sidebarItems, id);
      }
      // Update the previous value with the current value
      prevId.current = id;
    }
    dispatch(updateBreadcrumb(newBreadcrumb));
  }, [currentView, dispatch, calculateBreadcrumb, sidebarItems, id]);

  if (isLoading || isLoadingApi) {
    return <Loader />;
  } else
    return (
      <div>
        <Header />
        <div id="sidebar-container" className="sidebar-container">
          <SideBar />
        </div>
        <div
          id="main-content-area-container"
          className={`main-content-area-container ${theme}`}
        >
          {children}
        </div>
        <AlertComponent />
        <NotificationCenter
          onClose={handleNotificationToggle}
          open={notificationToggle}
        />
        <AppSnackbar
          show={notificationCenter.newExportSuccess}
          message="New file ready to download"
          severity="success"
        />
        <AppSnackbar
          show={notificationCenter.newExportFail}
          message="Please check Export notification area"
          severity="error"
        />
      </div>
    );
};

Workspace.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Workspace);
