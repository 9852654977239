import axios from 'axios';
import { IIdentityProviderConfig } from '../models/IIdentityProviderConfig';
import { User } from 'oidc-client-ts';

class AuthService {
  private config: IIdentityProviderConfig | null = null;

  public async initConfig(): Promise<void> {
    if (!this.config) {
      this.config = await this.fetchConfig();
    }
  }

  public getUser(): User | null {
    if (this.config) {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${this.config.authority}:${this.config.client_id}`
      );
      if (!oidcStorage) {
        return null;
      }

      return User.fromStorageString(oidcStorage);
    } else {
      return null;
    }
  }

  public getAccessToken(): string | null {
    const user = this.getUser();
    const access_token = user?.access_token;
    if (!access_token) console.warn('Access token not found!');
    return access_token ?? null;
  }

  private async fetchConfig(): Promise<IIdentityProviderConfig | null> {
    try {
      const response = await axios.get('/config/config.json');
      const { identityProviderConf } = response.data;
      const config: IIdentityProviderConfig = {
        baseUrl: identityProviderConf.redirectUri,
        authority: identityProviderConf.identityServerStatusCheckUrl,
        client_id: identityProviderConf.clientId,
        redirect_uri: identityProviderConf.redirectUri,
        post_logout_redirect_uri: identityProviderConf.redirectUri,
        scopes: ['openid'],
      };
      return config;
    } catch (error) {
      console.error('Error fetching configuration:', error);
      return null;
    }
  }
  public getConfig(): IIdentityProviderConfig | null {
    return this.config;
  }
}

export default new AuthService();
