import React, { useEffect, useState, useRef } from 'react';
import { User } from 'oidc-client-ts';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import AuthService from 'services/AuthService';

interface SessionTimeoutProps {
  timeSpan: number;
  onSessionExpired: () => void;
}

const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  timeSpan,
  onSessionExpired,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(timeSpan * 1000);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const userRef = useRef<User | null>(null);
  // eslint-disable-next-line no-undef
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const user = useAuth();

  const handleExtendSession = () => {
    user
      .signinSilent()
      .then(() => {
        setTimeLeft(timeSpan * 1000);
        setIsConfirmationOpen(false);
      })
      .catch((error: any) => {
        console.error('Error extending session:', error);
      });
  };

  const handleLogout = () => {
    user?.signoutRedirect();
  };

  useEffect(() => {
    userRef.current = AuthService.getUser();
    // Cleanup function to clear the interval
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 60000 && !isConfirmationOpen) {
      setIsConfirmationOpen(true);
    }
  }, [timeLeft, isConfirmationOpen]);

  useEffect(() => {
    if (AuthService.getUser()) {
      intervalIdRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => Math.max(0, prevTimeLeft - 1000));
      }, 1000);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }
  }, []);

  useEffect(() => {
    if (userRef.current && userRef.current.expires_in) {
      const expiresIn = userRef.current.expires_in * 1000; // Convert seconds to milliseconds
      setTimeLeft(expiresIn);
    }
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      onSessionExpired(); // Call the onSessionExpired function when timeLeft reaches 0
    }
  }, [timeLeft, onSessionExpired]);

  return (
    <>
      <Dialog open={isConfirmationOpen} onClose={handleLogout}>
        <DialogTitle>Extend my Session</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session will timeout in <span>{timeLeft / 1000}</span> seconds!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExtendSession} color="primary">
            Extend my Session
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionTimeout;
