import { createSlice } from '@reduxjs/toolkit';
import { Notifications } from 'types/types';

const initialState: Notifications = {
  notificationData: {
    TotalNeCount: 0,
    CurrentNeCount: 0,
    PopupInterval: 0,
    PopupEnabled: false,
    IsNeCountEnabled: false,
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotificationData: (state, action) => {
      state.notificationData = action.payload;
    },
  },
});

export const { updateNotificationData } = notificationsSlice.actions;
export default notificationsSlice.reducer;
