import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import AuthService from 'services/AuthService';
import { apiUrls } from 'constants/apiUrls';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

const handleRequest = async (request: AxiosRequestConfig) => {
  // Ensure that AuthService configuration is initialized before making API request
  await AuthService.initConfig();

  const accessToken = AuthService.getAccessToken();
  if (!accessToken) console.warn('Access token not found');
  request.headers &&
    (request.headers['Authorization'] = `Bearer ${accessToken}`);

  if (
    request.url == apiUrls.releaseNotesUrl ||
    request.url == apiUrls.userGuideUrl
  )
    request.responseType = 'blob';

  return request;
};

const handleError = (error: any) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (401 === error.response?.status) {
    console.info('Session has expired!', error);
    window.location.replace('/sessionExpired');
  }
  return Promise.reject(error);
};

abstract class ApiBase {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    return handleRequest(config);
  };

  private _handleResponse = ({ data }: AxiosResponse) => {
    return data;
  };

  protected _handleError = (error: any) => {
    return handleError(error);
  };
}

export { ApiBase };
